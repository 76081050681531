import AppBar from "@mui/material/AppBar/AppBar";
import Box from "@mui/material/Box/Box";
import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import { useDispatch, useSelector } from 'react-redux';
import { setTabIndex } from '../reducer/tabState';
import { Dialog, IconButton, Modal } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useState } from "react";
import TaskFilter from "./TaskFilter";
import TaskPanel from "./TaskPanel";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from "../theme";
import { useMsal } from '@azure/msal-react';
import { setEventOpen, setEventMode } from '../reducer/eventStatus';


export default function Header({ startDate, endDate, targetDate }: { startDate: string, endDate: string, targetDate: string | null }) {
  const tabIndex = useSelector((state: any) => state.tab);
  const eventStatus = useSelector((state: any) => state.event);

  const dispatch = useDispatch();
  const { accounts } = useMsal();
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setTabIndex(newValue));
  }; 
  
  const handleOpen = () => { dispatch(setEventOpen(true)); dispatch(setEventMode('add')); };
  const handleClose = () => { dispatch(setEventOpen(false)); };

  const [filterOpen, setFilterOpen] = useState(false);
  const handleFilterOpen = useCallback(() => setFilterOpen(true), []);
  const handleFilterClose = useCallback(() => setFilterOpen(false), []);

  return (
    <Box sx={{ flex: '0 0 auto', overflow: 'hidden' }}>
      <AppBar position="static" >
        <Toolbar sx={{ pt: 2, pb: 1, gap: '5px', minHeight: '50px',  }}>
          <Typography variant="h2" component="div" sx={{ pl: 0, flexGrow: 1, fontWeight: 700 }}>
            {accounts[0]['name']}
          </Typography>
          <AddIcon onClick={handleOpen}/>
          <TuneIcon onClick={handleFilterOpen}/>
        </Toolbar>
      </AppBar>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex.selectedIndex} onChange={handleChange} variant='fullWidth'>
          <Tab label="할 일" className="header-tab-label" />
          <Tab label="캘린더" className="header-tab-label" />
        </Tabs>
      </Box>
      <Modal open={filterOpen} onClose={handleFilterClose}>
        <TaskFilter />
      </Modal>
      <Dialog fullScreen open={eventStatus.open} onClose={handleClose} >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <ArrowBackIcon sx={{ strokeWidth: 1, stroke: theme.palette.info.main }}/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <TaskPanel mode={eventStatus.mode} onCloseHandler={handleClose} endDate={endDate} startDate={startDate} targetDate={targetDate} 
          readTaskId={eventStatus.taskId} readTaskType={eventStatus.taskType} readTargetDate={eventStatus.targetDate} readTask={eventStatus.task}
        />
      </Dialog>
    </Box>
  );
}