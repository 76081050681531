import { Fragment, useRef, useState, useMemo, useCallback, Dispatch, SetStateAction, useEffect } from "react";

import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import List from "@mui/material/List/List";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import { TeamTaskIcon, MyTaskIcon, RadioButtonCheckedIcon, RadioButtonUncheckedIcon } from "./Icons";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";

import Button from "@mui/material/Button/Button";

import ListItem from "@mui/material/ListItem/ListItem";
import Checkbox from "@mui/material/Checkbox/Checkbox";

// import { RootState } from "../store/store";
import { setFuncBarStatus } from '../reducer/funcBarStatus';
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { TaskListType, fetchStoreTaskList, fetchTaskList, updateTaskStatus } from "../api/taskRequest";
import { setEventMode, setEventOpen, setEventSequence, setEventTaskId, setEventTaskType, setEventTargetDate, setEventTask } from "../reducer/eventStatus";
import { setSeletedTasks } from "../reducer/selectedList";

interface SwipeableListItemProps {
  title: string;
  task_id: string;
  subTitle: string;
  workType: string;
  status: string;
  targetDate: string;
  swipedItemId: string | null;
  setSwipedItemId: Dispatch<SetStateAction<string | null>>;
  checked: boolean;
  onCheckChange: (id: string, checked: boolean) => void;
  bizplCd: string | undefined;
}

interface TaskListProps {
  type: string;
  taskList: TaskListType;
  bizplCd?: string;
}

const MAX_SWIPE_DISTANCE = 128;
const MAX_SWIPE_DISTANCE_SMALL = 64;

const SwipeableListItem: React.FC<SwipeableListItemProps> = ({
  title, task_id, subTitle, workType, status, targetDate, swipedItemId, setSwipedItemId, checked, onCheckChange, bizplCd
}) => {
  const [swipeDistance, setSwipeDistance] = useState(0);
  const selectedList = useSelector((state: any) => state.selectedList);

  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const dist = status === "complete" ? MAX_SWIPE_DISTANCE_SMALL : MAX_SWIPE_DISTANCE;

  console.log(bizplCd);

  useEffect(() => {
    if (swipedItemId !== task_id) {
      setSwipeDistance(0);
    }
  }, [swipedItemId, task_id]);

  const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.touches[0].clientX;
  }, []);

  const handleTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    touchEndX.current = e.touches[0].clientX;
    const distance = touchStartX.current - touchEndX.current;
    setSwipeDistance(Math.min(distance, dist));
  }, [dist]);

  const handleTouchEnd = useCallback(() => {
    if (swipeDistance > dist * 0.7) {
      setSwipeDistance(dist);
      setSwipedItemId(task_id);
    } else {
      setSwipeDistance(0);
    }
  }, [swipeDistance, dist, setSwipedItemId, task_id]);

  const handleTaskSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onCheckChange(task_id, e.target.checked);
  }, [task_id, onCheckChange]);

  const dispatch = useDispatch();
  const eventStatus = useSelector((state: any) => state.event);

  async function onChangeStatus(id: any, status: string, bizplCd: string | undefined): Promise<void> {
    if(await updateTaskStatus(id, status, bizplCd) === "200") dispatch(setEventSequence(eventStatus.sequence + 1));
  }

  function onTaskClick(id: string, taskType: string, task: string, targetDate: string): void {
    dispatch(setEventOpen(true)); dispatch(setEventMode('read'));
    dispatch(setEventTaskId(id));
    dispatch(setEventTaskType(taskType));
    dispatch(setEventTask(task));
    dispatch(setEventTargetDate(targetDate));
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }} >
      <Box
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        sx={{
          display: 'flex',
          width: '100%',
          transition: 'transform 0.3s ease-out',
          transform: `translateX(-${swipeDistance}px)`
        }}
      >
        <ListItem sx={{ px: '0px', py: '0px', flex: '1 0 100%' }}>
          { bizplCd === undefined && (
          <Checkbox icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
            sx={{ '&:hover': { bgcolor: 'transparent' }, ml: '7px' }} onChange={handleTaskSelect} checked={checked}
            disabled={selectedList.status !== "panel1"}
          />)}
        
          <ListItemButton dense 
            onClick={() => onTaskClick(task_id, workType, title, targetDate)}
            sx={selectedList.status === "panel2" || bizplCd !== undefined ? {ml: '16px'} : {ml: '0px'}}
          >
            { workType === 'private' ? <MyTaskIcon /> : <TeamTaskIcon /> }
            <ListItemText 
              primary={title} 
              secondary={workType === 'private' ? null : subTitle} 
              sx={{ width: '100%', pl: 1, color: (status === 'complete' ? "text.disabled" : "text.primary") }} 
            />
          </ListItemButton>
        </ListItem>
        {
          status === "complete" && (
            <Box 
              display="flex"
              flexDirection="row"
              sx={{ flex: '0 0 100px', height: '100%' }}
            >
              <Button aria-label="complete" variant="contained" color="error"
                sx={{ fontSize: "0.9rem", borderRadius: '0px', height: '70.8px', width: '64px', padding: '0px' }} 
                onClick={() => onChangeStatus(task_id, "inprogress", bizplCd)}
              >되돌리기</Button>
            </Box>
          )
        }
        {
          status !== "complete" && (
            <Box 
              display="flex"
              flexDirection="row"
              sx={{ flex: '0 0 100px', height: '100%' }}
            >
              <Button aria-label="complete" variant="contained" color="primary"
                sx={{ fontSize: "0.9rem", borderRadius: '0px', height: '70.8px', width: '64px' }} 
                onClick={() => onChangeStatus(task_id, "complete", bizplCd)}
              >완료</Button>
              <Button aria-label="delete" variant="contained" color="warning"
                sx={{ fontSize: "0.9rem", borderRadius: '0px', height: '70.8px', width: '64px' }} 
                onClick={() => onChangeStatus(task_id, "delete", bizplCd)}
              >삭제</Button>
            </Box>
          )
        }
      </Box>
    </Box>
  );
};

export default function TaskList({ type, taskList, bizplCd }: TaskListProps) {
  const [tasks, setTasks] = useState<TaskListType>(taskList);
  const [swipedItemId, setSwipedItemId] = useState<string | null>(null);
  
  const dispatch = useDispatch();

  const workFilter = useSelector((state: any) => state.workFilter);
  const dateRange = useSelector((state: any) => state.dateRange);
  const eventStatus = useSelector((state: any) => state.event);
  
  const taskType = type;

  const { data, refetch } = useQuery<TaskListType, Error>({
    queryKey: ['TaskList', dateRange.start_date, dateRange.end_date, eventStatus.sequence],
    queryFn: () => bizplCd !== undefined ? fetchStoreTaskList(dateRange.start_date, dateRange.end_date, bizplCd) : fetchTaskList(dateRange.start_date, dateRange.end_date),
    initialData: tasks,
  });

  useEffect(() => {
    refetch();
  }, [dateRange, refetch]);

  useEffect(() => {
    if(data) setTasks(data);
  }, [data]);

  useEffect(() => {
    const anyChecked = tasks.some(task => task.checked);
    dispatch(setFuncBarStatus(anyChecked ? true : false));
  }, [dispatch, tasks]);

  const checkedTaskIds = useMemo(() => 
    dispatch(setSeletedTasks(tasks.filter(task => task.checked).map(task => task.taskId))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks]
  );

  const handleCheckChange = useCallback((task_id: string, checked: boolean) => {
    setTasks(prevTasks => 
      prevTasks.map(task => {
        if (task.taskId === task_id) {
          const updatedTask = { ...task, checked };
          return updatedTask;
        } else {
          return task;
        }
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTaskIds, dispatch]);

  const memoizedTaskList = useMemo(() => (
    <List sx={{ p: 0 }}>
      {tasks && tasks
      .filter(task => (bizplCd === undefined ? task.taskStatus === taskType : task.bizplInfo[0].assignedTaskStatus === taskType) && (workFilter.selectedWorkStatus === "all" ? true : task.taskType === workFilter.selectedWorkStatus))
      .map((task, index) => (
        <Fragment key={task.taskId}>
          <SwipeableListItem
            title={task.title}
            task_id={task.taskId}
            subTitle={task.createdBy}
            workType={task.taskType}
            status={bizplCd === undefined ? task.taskStatus : task.bizplInfo[0].assignedTaskStatus}
            targetDate={task.targetDate}
            swipedItemId={swipedItemId}
            setSwipedItemId={setSwipedItemId}
            checked={task.checked}
            onCheckChange={handleCheckChange}
            bizplCd={bizplCd}
          />
          {index < tasks.length - 1 && (
            <Divider variant="fullWidth" component="li" sx={{
              flexGrow: 1,
              borderColor: '#E0E0E0',
              borderBottomWidth: '1px',
              borderTopWidth: '0px',
            }} />
          )}
        </Fragment>
      ))}
    </List>
  ), [tasks, taskType, workFilter.selectedWorkStatus, swipedItemId, handleCheckChange, bizplCd]);


  return (
    <Grid item xs={12} sx={{ height: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ flex: "1 1 auto", overflow: "auto" }}>
          {memoizedTaskList}
        </Box>
      </Box>
    </Grid>
  );
}
