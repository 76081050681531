import { ChangeEvent, forwardRef, useRef, useState } from "react";

import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker/MobileDatePicker";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton/ToggleButton";

import Button from "@mui/material/Button/Button";
import FormControl from "@mui/material/FormControl/FormControl";
import InputBox from "./InputBox";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import theme from "../theme";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/ko';
import { submitTaskInfo } from "../api/taskRequest";
import Alert from "./Alert";
import { setEventMode, setEventOpen, setEventSequence } from "../reducer/eventStatus";
import { useDispatch, useSelector } from 'react-redux';

dayjs.locale('ko');

const style = {
    display: "flex",
    flexDirection: "column",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    width: "100%",
    height: "100dvh",
    transition: "height 0.3s ease",
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    pt: 9,
  };


function formatDate(date: Dayjs) {
  const year = date.year();
  const month = String(date.month() + 1).padStart(2, '0');
  const day = String(date.date()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

interface TaskProps {
  onCloseHandler: () => void;
  startDate: string;
  endDate: string;
  targetDate: string | null;
  mode: string;
  readTaskId: string | null;
  readTaskType: string | null;
  readTargetDate: string | null;
  readTask: string | null;
}

const Task = forwardRef<HTMLDivElement, TaskProps>(({ onCloseHandler, startDate, endDate, targetDate, mode, readTaskId, readTaskType, readTargetDate, readTask }, ref) => {
  const [isReadOnly, setIsReadOnly] = useState(mode === 'read' || mode === 'edit' ? true : false);

  const taskTypeRef = useRef<HTMLDivElement>(null);
  const targetDateRef = useRef<HTMLDivElement>(null);
  const eventStatus = useSelector((state: any) => state.event);
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    (!isReadOnly && targetDate)
      ? dayjs(targetDate)
      : readTargetDate
      ? dayjs(readTargetDate)
      : dayjs()
  );

  const [alignment, setAlignment] = useState(isReadOnly ? readTaskType ?? "private" : "private");

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null 
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const [inputList, setInputList] = useState<string[]>(isReadOnly ? [readTask ?? ""] : [""]);

  // handle input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    const list = [...inputList];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    setInputList(remove);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  const [alertOpen, setAlertOpen] = useState(false);

  const handleEdit = async () => {
    if(isReadOnly) setIsReadOnly(false);
    dispatch(setEventMode('edit'));
  }
  const handleCancel = async () => {
    dispatch(setEventOpen(false));
  }
  const handleSubmit = async () => {
    const formData = {
      taskId: readTaskId,
      targetDate: selectedDate? formatDate(selectedDate) : formatDate(dayjs()),
      taskType: taskTypeRef.current?.querySelector('[aria-pressed="true"]')?.getAttribute('value') ?? "private",
      taskList: inputList,
    };
    
    if(await submitTaskInfo(formData.taskId, formData.taskType, formData.targetDate, formData.taskList, startDate, endDate) === "200"){
      setAlertOpen(true);
      dispatch(setEventSequence(eventStatus.sequence + 1));
      dispatch(setEventOpen(false));
    }
  };

  return (
    <Box sx={style} alignContent={"center"} alignItems={"center"} >
      <Stack spacing={3} direction={"column"} sx={{ width: "100%", height: '240.63px', pb: 1 }}>
        <Stack spacing={1} direction={"column"} sx={{ width: "100%" }}>
          <Typography variant="body1">구분</Typography>
          <FormControl variant="standard">
            <ToggleButtonGroup ref={taskTypeRef} disabled={isReadOnly} value={alignment} exclusive onChange={handleAlignment} sx={{ gap: 1.5 }}>
              <ToggleButton value="team" className="btn-toggle-team">팀 할 일</ToggleButton>
              <ToggleButton value="private" className="btn-toggle-private">내 할 일</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Stack>
        <Stack spacing={1} direction={"column"} sx={{ width: "100%" }}>
          <Typography variant="body1">기한</Typography>
          <FormControl variant="standard" sx={{ borderRadius: 10, '& .MuiTextField-root': { borderRadius: '10px' } }} >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko" >
              <MobileDatePicker
                ref={targetDateRef}
                readOnly={isReadOnly}
                showDaysOutsideCurrentMonth
                slots={{ toolbar: () => null }}
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                }}
                slotProps={{
                  textField: {
                    inputProps: {
                      value: selectedDate ? selectedDate.format('YYYY년 M월 D일, dddd') : '',
                    },
                  },
                }}
                
                sx={{ 
                  'label + &': { marginTop: theme.spacing(3) },
                  backgroundColor: isReadOnly ? "#FFF" : "#F2F2F2",
                  "& .MuiOutlinedInput-notchedOutline": { border: 'none' },
                  '& .MuiInputBase-root': { borderRadius: '10px' },
                  '& .MuiInputBase-input': {
                    borderRadius: isReadOnly ? '0px' : '20px',
                    position: 'relative',
                    backgroundColor: isReadOnly ? "#FFF" : "#F2F2F2",
                    borderBottom: isReadOnly ? '1px solid #999' : "none",
                    fontSize: 14,
                    width: '100%',
                    padding: '15px 12px',
                    transition: theme.transitions.create([ 'border-color', 'background-color', 'box-shadow' ]),
                    '&:focus': { borderColor: theme.palette.primary.main },
                  }, }}
                  />
            </LocalizationProvider>
          </FormControl>
        </Stack>
        <Stack direction={"row"} sx={{ width: "100%", justifyContent: 'space-between' }}>
          <Typography variant="body1">할 일</Typography>
          <IconButton size="large" sx={{p:0, display: isReadOnly ? "none" : "block" }} onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Box sx={{ flexGrow: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}>
          <Stack spacing={1} direction={"column"} sx={{ width: "100%" }}>
            {inputList.map((value, index) => (
              <FormControl variant="standard" key={index} >
                <InputBox placeholder={`내용${inputList.length === 1 ? '' : String(index+1)}`} size={"small"}
                  onChange={ (e) => handleInputChange(e, index) }
                  value={value}
                  endAdornment={
                    !isReadOnly && (index > 0) && <InputAdornment position="end">
                      <IconButton onClick={() => handleRemoveClick(index)}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  readOnly={isReadOnly}
                  sx={{borderRadius: isReadOnly ? '0px' : '20px',
                  position: 'relative',
                  backgroundColor: isReadOnly ? "#FFF" : "#F2F2F2",
                  borderBottom: isReadOnly ? '1px solid #999' : "none",
                  '& .MuiInputBase-input': {backgroundColor: isReadOnly ? "#FFF" : "#F2F2F2",}}}
                />
              </FormControl>
            ))}
          </Stack>
        </Box>
      </Box>
      {mode === 'add' ? (
        <Box sx={{ height: '58px', width: '100%' }}>
          <Button sx={{ width: '100%', fontSize: '1rem', backgroundColor: '#413F3F', borderRadius: 15, py: '15px' }} 
            variant="contained"
            onClick={handleSubmit}
          >등록하기</Button>
        </Box>
      ) : (
        mode === 'edit' ?
        (<Stack direction={"row"} sx={{ height: '58px', width: '100%', gap: 1 }}>
          <Button sx={{ width: '50%', fontSize: '1rem', backgroundColor: '#ECECEC', borderRadius: 15, py: '15px', color: '#808080' }} 
            variant="contained"
            onClick={handleCancel}
          >취소하기</Button>
          <Button sx={{ width: '50%', fontSize: '1rem', backgroundColor: '#413F3F', borderRadius: 15, py: '15px' }} 
            variant="contained"
            onClick={handleSubmit}
          >저장하기</Button>
        </Stack>)
        :
        (<Box sx={{ height: '58px', width: '100%' }}>
          <Button sx={{ width: '100%', fontSize: '1rem', backgroundColor: '#413F3F', borderRadius: 15, py: '15px' }} 
            variant="contained"
            onClick={handleEdit}
          >복사하기</Button>
        </Box>)
      )}
      {alertOpen && <Alert
        isConfirm={false}
        isOpen={alertOpen}
        alertContents="작업이 성공적으로 저장되었습니다."
        alertCloseHandler={() => {
          setAlertOpen(false);
          onCloseHandler();
        }}
      />}
    </Box>
  );
});

export default Task;